import React from 'react'
const AnswerModal = props => {
  return (
    <div id='answermodal' className='fixed flex top-0 left-0 h-screen w-screen z-50'>
      <div className='fixed top-0 left-0 h-screen w-screen z-40 bg-black opacity-75' />
      <div className='bg-white w-10/12 md:w-3/5 lg:w-1/2 m-auto p-8 rounded-lg z-50 flex flex-col text-center'>
        <h2 className='text-black font-semibold text-lg mb-4'>É stato trovato un test in corso</h2>
        <p class='w-full font-normal mb-2 text-left'>Premendo su "Continua" verranno inviate le precedenti risposte.</p>
        <p class='w-full font-normal mb-2 text-left'>Premendo su "Ricomincia test", le precedenti risposte verranno cancellate e potrai ricominciare il test.</p>
        <div className='flex justify-between flex-col md:flex-row mt-4'>
          <div onClick={() => props.onClose()} className='bg-white border border-blue flex shadow-box rounded-lg mx-auto cursor-pointer min-w-40 justify-center mb-4 md:mb-0'>
            <span className='text-blue text-sm uppercase py-2 px-4 xl:px-8 font-semibold'>Ricomincia test</span>
          </div>
          <div onClick={() => props.onSubmit()} className='bg-blue flex shadow-box rounded-lg text-white mx-auto cursor-pointer min-w-40 justify-center'>
            <span className='text-white text-sm uppercase py-2 px-4 xl:px-8 font-semibold'>Continua</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export { AnswerModal }
