import React, { useEffect, useState } from 'react'
import { Switch, Route } from 'react-router-dom'
import { fetchDetail, submitTest, submitFormattedTest, checkQuestions, sendTime, fetchTime } from './../utils'
import { POLLING_TIMER, INACTIVITY_TIMER } from './../config'
import { Test } from './Test'
import { MouseModal } from './MouseModal'
import { Video } from './Video'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import Loader from 'react-loader-spinner'
import AOS from 'aos'
import 'aos/dist/aos.css'

const Detail = (props) => {
  const [detail, setDetail] = useState({})
  const [questions, setQuestions] = useState([])
  const [results, setResults] = useState({})
  const [message, setMessage] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [timers, setTimers] = useState({})

  // course Timer
  const [courseTimerStarted, setCourseTimerStarted] = useState(false)
  const [courseTimer, setCourseTimer] = useState(0)

  // video Timer
  const [videoTimerState, setVideoTimerState] = useState(0)

  // mouse Timer and modal
  const [isMouseStill, setMouseStill] = useState(false)
  const [mouseStillTimer, setMouseStillTimer] = useState(0)
  const [mouseTimerStarted, setMouseTimerStarted] = useState(false)

  useEffect(() => {
    AOS.init({
      duration: 1000
    })
  })

  const getDetails = () => {
    setLoading(true)
    const { params } = props.match
    const { id } = params
    fetchDetail(id)
      .then(res => {
        if (res) {
          setDetail(res)
          setQuestions(res.questions || [])
          fetchTime(id)
            .then(res => {
              if (res) {
                setTimers(res)
              }
            })
            .catch(e => {
              console.log(e)
            })
        }
      })
      .catch(e => { console.log(e) })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleTestSubmit = evt => {
    evt.preventDefault()
    if (checkQuestions(questions)) {
      setLoading(true)
      submitTest(questions, detail)
        .then(res => {
          // console.log(res)
          setResults(res || {})
          setLoading(false)
        })
        .catch(e => {
          console.error(e)
          setMessage('Si é verificato un errore')
          setLoading(false)
        })
    } else setMessage('É obbligatorio rispondere a tutte le domande')
  }

  const handleFormattedTestSubmit = (data) => {
    setLoading(true)
    submitFormattedTest(data)
      .then(res => {
        // console.log(res)
        setResults(res || {})
        setLoading(false)
      })
      .catch(e => {
        console.error(e)
        setMessage('Si é verificato un errore')
        setLoading(false)
      })
  }

  const handleAnswer = (questionID, answerID) => {
    let newQuestions = [...questions]
    newQuestions = newQuestions.map(item => {
      if (item.id === questionID) {
        item.answer = answerID
      }
      return item
    })

    setQuestions(newQuestions)
  }

  useEffect(getDetails, [props.match.params.id])

  const updateVideoInfo = data => {
    if (data && data.playedSeconds) {
      setVideoTimerState(parseInt(data.playedSeconds))
    }
  }

  // COURSE TIMER (SENDER) START
  const startCourseTimer = () => {
    if (timers.course_time) {
      setCourseTimer(prevTime => timers.course_time)
    }
    if (!courseTimerStarted) {
      setCourseTimerStarted(true)
    }
  }
  useEffect(() => {
    const { params } = props.match
    const { id } = params
    if (courseTimerStarted) {
      const timer = window.setInterval(() => {
        setCourseTimer(prevTime => prevTime + 1)
        if (courseTimer && courseTimer % POLLING_TIMER === 0) {
          sendInfo(id, courseTimer, videoTimerState)
        }
      }, 1000)
      return () => {
        window.clearInterval(timer)
      }
    }
  }, [courseTimerStarted, courseTimer, videoTimerState])
  // COURSE TIMER END

  // MOUSE TIMER START
  const resetMouseTimer = (fromButton = false) => {
    if (!isMouseStill || fromButton) {
      setMouseStillTimer(0)
      if (mouseStillTimer === 0) {
        setMouseStill(false)
        setMouseTimerStarted(true)
      }
    }
  }
  const startMouseTimer = () => {
    setMouseTimerStarted(true)
  }
  useEffect(() => {
    var interval
    if (mouseTimerStarted) {
      interval = setInterval(() => {
        setMouseStillTimer(mouseStillTimer + 1)
        if (mouseStillTimer > INACTIVITY_TIMER) {
          setMouseStill(true)
          setMouseStillTimer(0)
          setMouseTimerStarted(false)
        }
      }, 1000)
    } else {
      clearInterval(interval)
    }
    return () => clearInterval(interval)
  }, [mouseTimerStarted, mouseStillTimer])
  useEffect(() => {
    resetMouseTimer()
  }, [props.event])
  const stopTimerMouse = () => {
    setMouseTimerStarted(false)
  }
  // MOUSE TIMER END

  const sendInfo = data => {
    const { params } = props.match
    const { id } = params
    if (data && data.seconds > 0) {
      setVideoTimerState(data.seconds)
    }
    let video = videoTimerState
    if (data && data.seconds) {
      video = data.seconds
    }
    if (courseTimer > 0) {
      if (data.reset) {
        sendTime(id, courseTimer, video, true)
      } else {
        sendTime(id, courseTimer, video)
      }
    }
  }

  useEffect(() => {
    startCourseTimer()
    startMouseTimer()
  }, [timers])

  return (
    <article onMouseMove={e => { e.preventDefault(); resetMouseTimer() }}>
      {isMouseStill && (
        <MouseModal closeModal={() => { resetMouseTimer(true) }} />
      )}
      <nav className='mb-4 w-full flex justify-between items-center'>
        <span onClick={() => { window.location.href = '/' }} className='text-sm lg:text-base text-black font-semibold cursor-pointer'>{'<'} Torna alla lista</span>
        {detail.days_missing !== '-' && (
          <span className='text-xs lg:text-base opacity-50 text-black font-semibold text-right'>{parseInt(detail.days_missing) > 0 ? detail.days_missing : 0} giorni rimanenti</span>
        )}
      </nav>

      <h1 className='text-3xl md:text-4xl font-semibold text-black mb-8 leading-snug'> {Object.keys(results).length ? 'Il tuo risultato' : detail.name}</h1>
      {isLoading ? (
        <div className='flex justify-center w-full'>
          <Loader
            type='Circles'
            color='#2ba0d0'
            height={100}
            width={100}
            timeout={10000} // 3 secs
            className='mx-auto'
          />
        </div>
      ) : (
        <Switch>
          <Route path='/courses/:id/test'>
            <Test
              questions={questions}
              message={message}
              isCompleted={Object.keys(results).length ? results : false}
              onAnswer={handleAnswer}
              onSubmit={handleTestSubmit}
              submitFormattedAnswers={data => handleFormattedTestSubmit(data)}
            />
          </Route>
          <Route path='/courses/:id'>
            <Video
              url={detail.link_video}
              detail={detail}
              timers={timers}
              sendInfo={data => sendInfo(data)}
              isMouseStill={isMouseStill}
              updateVideoInfo={data => updateVideoInfo(data)}
              streamingCode={detail.streaming_code}
              stopTimerMouse={() => stopTimerMouse()}
            />
          </Route>
        </Switch>
      )}
    </article>
  )
}

export { Detail }
