import React, { useEffect, useState } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { AnswerModal } from './AnswerModal'

const Test = ({ questions, message, isCompleted, onSubmit, onAnswer, submitFormattedAnswers }) => {
  const [isAnswerModal, setAnswerModal] = useState(false)
  useEffect(() => {
    AOS.init({
      duration: 1000
    })
  })
  const areTriesLeft = () => {
    const times = parseInt(isCompleted.times)
    return !!(times < 3)
  }
  const isMoreThanOneTry = () => {
    if (getTriesLeft() > 1) return true
    return false
  }
  const getTriesLeft = () => {
    const times = parseInt(isCompleted.times)
    return 3 - times
  }

  const isAnswersShowable = () => {
    if (isCompleted.total_questions && isCompleted.right_answers !== '') return true
    return false
  }

  useEffect(() => {
    if (isCompleted && !areTriesLeft()) {
      setTimeout(() => {
        window.location.href = window.location.href.split('/test')[0]
        window.location.reload()
      }, 5000)
    }
  }, [isCompleted])

  useEffect(() => {
    const path = window.location.href.split('courses/')[1]
    const oldAnswer = window.localStorage.getItem(path)
    if (oldAnswer && oldAnswer.length) {
      setAnswerModal(true)
    }
  }, [])

  const deleteOldAnswers = () => {
    const path = window.location.href.split('courses/')[1]
    window.localStorage.removeItem(path)
    setAnswerModal(false)
  }

  const submitOldAnswers = () => {
    setAnswerModal(false)
    const path = window.location.href.split('courses/')[1]
    const oldAnswer = window.localStorage.getItem(path)
    const oldAnswerParsed = JSON.parse(oldAnswer)
    submitFormattedAnswers(oldAnswerParsed)
  }

  return (
    !(typeof isCompleted === 'object' && Object.keys(isCompleted).length) ? (
      <article>
        {isAnswerModal && (
          <AnswerModal onClose={() => deleteOldAnswers()} onSubmit={() => submitOldAnswers()} />
        )}
        <form onSubmit={onSubmit}>
          <section>
            {questions.map((q, index) => (
              <div key={q.id} className='flex flex-col mb-8'>
                <span className='text-lg text-light-grey uppercase'> DOMANDA {index + 1}</span>
                <h4 className='text-xl font-semibold text-black pb-3 '>{q.question}</h4>
                {(q.answers || []).map(a => (
                  <div key={`${q.id}-${a.id}`} className='flex justify-start items-center pb-2'>
                    <div style={{ minWidth: '1rem' }} className={`w-4 h-4 rounded border-2 border-blue ${a.id === q.answer ? 'bg-blue' : 'bg-white'}`} onClick={() => onAnswer(q.id, a.id)} />
                    <span className='text-black ml-4'>
                      {a.answer_text}
                    </span>
                  </div>
                ))}
              </div>
            ))}
          </section>
          {message && message.length && (
            <span className='text-red-600 font-semibold text-sm mb-16 w-full'>{message}</span>
          )}
          <div>
            <button type='submit' className='bg-blue flex shadow-box rounded-lg text-white mx-auto uppercase outline-none text-base py-2 px-8 mt-4 font-semibold'>Termina il test</button>
          </div>
        </form>
      </article>
    ) : (
      <div className='w-full flex flex-col'>
        <div className='flex lg:flex-row flex-col md:items-center'>
          <div data-aos='fade-top' className='mb-8 md:mb-0 mx-auto md:mx-0 lg:mr-8'>
            {isCompleted.passed ? (
              <img src='success.png' alt='success' className='w-1/2 mb-4 lg:mb-0 lg:w-full' />
            ) : (
              <img src='fail.svg' alt='failed' className='w-1/2 mx-auto mb-4 lg:m-0 lg:w-ful' />
            )}
          </div>
          <div className='flex flex-col flex-grow'>
            <h3 className='text-black text-lg font-bold'>
              {isCompleted.passed ? 'Hai superato il test!' : 'Hai fallito il test'}
            </h3>
            {isAnswersShowable() && (
              <span className='flex-grow md:ml-4 text-black text-lg'>
                Risultato: <b className='font-semibold'>{isCompleted.right_answers || 0}/{isCompleted.total_questions}</b>
              </span>
            )}
            {!isCompleted.passed && (
              <span className='flex-grow md:ml-4 text-black text-lg'>
                {areTriesLeft()
                  ? isMoreThanOneTry()
                    ? 'Ti rimangono ' + getTriesLeft() + ' tentativi.'
                    : 'Ti rimane un tentativo.'
                  : 'Hai raggiunto il numero massimo di tentativi. Verrai riportato al corso.'}
              </span>
            )}
          </div>
        </div>
        {(!isCompleted.passed && areTriesLeft()) && (
          <button
            className='outline-none bg-blue flex shadow-box rounded-lg text-white mt-4 w-32 justify-center mx-auto'
            onClick={() => {
              window.location.href = window.location.href.split('/test')[0]
              window.location.reload()
            }}
          >
            <span className='text-white text-base uppercase py-2 px-8 font-semibold cursor-pointer'>Riprova</span>
          </button>
        )}
        {isCompleted.passed && isCompleted.wrong_answers && (
          <div className='w-full flex flex-col mt-8'>
            <h4 className='w-full text-left text-black text-xl font-bold mb-2 uppercase'>
            Risposte sbagliate:
            </h4>
            {isCompleted.wrong_answers && isCompleted.wrong_answers.map((answ, index) => (
              <div key={index} className='w-full flex flex-col mb-6'>
                <div className='w-full flex flex-col mb-2'>
                  <span className='text-lg font-semibold text-black italic'>"{answ.question_text}"</span>
                </div>
                <span className='text-md text-red-600 mb-2'> {answ.wrong_answer} </span>
                <span className='text-md text-green-600 mb-2'> {answ.right_answer} </span>
              </div>
            ))}
          </div>
        )}
      </div>
    )
  )
}

export { Test }
