import React, { useEffect, useState, useRef, useContext } from 'react'
import { handleStartTest, handleStartCourse } from './../utils'
import { RestartModal } from './RestartModal'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import ReactPlayer from 'react-player'
import Loader from 'react-loader-spinner'
import 'aos/dist/aos.css'
import { ZOOM_BASE_URL } from '../config'
import { GlobalStore } from '../stores/Global'

const Video = props => {
  const globalStore = useContext(GlobalStore)
  const account = globalStore.state.loggedAccount || JSON.parse(window.localStorage.getItem('loggedAccount'))
  const [isRestartModalOpen, setRestartModalOpen] = useState(false)
  const [isConfirmed, setConfirmed] = useState(false)
  const [isVideoWatched, setVideoWatched] = useState(true)
  const [isIframeLoading, setIframeLoading] = useState(true)
  const [isMessage, setMessage] = useState('')

  const refPlayer = useRef(null)

  const handleTestClick = () => {
    if (props.detail.status !== 'PASSED') {
      return handleStartTest(props.detail)
        .then(res => {
          if (res.ok) {
            window.location.href = '/#/courses/' + props.detail.id + '/test'
          } else {
            if (res.status === 403) {
              window.location.href = '/#/list?expired'
            }
            setMessage('Non puoi ancora accedere al test')
          }
        })
        .catch(e => {
          console.error(e)
          setMessage('Si é verificato un errore')
        })
    } else {
      setMessage('Non puoi ancora accedere al test')
    }
  }

  const handleVideoClick = () => {
    if (props && props.detail && props.detail.status === 'ASSIGNED') {
      handleStartCourse(props.detail)
        .then(res => {
          setVideoWatched(true)
          console.log('COURSE OK')
        })
        .catch(e => {
          setVideoWatched(false)
          console.error(e)
        })
    }
  }

  useEffect(() => {
    handleVideoClick()
    if (isAlreadyTested()) {
      setConfirmed(true)
      props.stopTimerMouse()
    }
  }, [])

  useEffect(() => {
    const timers = props.timers
    if (timers && refPlayer && refPlayer.current) {
      try {
        refPlayer.current.seekTo(timers.video_time, 'seconds')
      } catch (e) {
        console.log(e)
      }
    }

    if (timers && refPlayer && refPlayer.current) {
      const duration = refPlayer.current.getDuration()
      if (duration && timers.video_time >= parseInt(duration) - 2) {
        props.updateVideoInfo({ playedSeconds: timers.video_time })
        console.log('Video visto tutto: test accessibile')
        setConfirmed(true)
      }
    }
  }, [props.timers])

  useEffect(() => {
    if (props.detail && props.detail.status === 'TESTED') {
      setConfirmed(true)
      props.stopTimerMouse()
    }
  }, [props.detail])

  // Manage connection with zoom iframe
  useEffect(() => {
    const innerWin = document.getElementsByTagName('iframe')['zoom-player']
    if (!innerWin) {
      console.warn('Cannot connect to iframe')
      return
    }
    console.log('IFrame Found')
    function parseMessageEvents (data) {
      if (data.origin !== ZOOM_BASE_URL) {
        console.warn('Blocked not allowed domain', data.origin)
        return
      }
      console.log('iFrame message', data)
      if (!data.data || !data.data.status) {
        console.error('Broken message from zoom')
        return
      }
      switch (data.data.status) {
        case 'ping': props.updateVideoInfo({ seconds: data.data.time, playedSeconds: data.data.time }); break
        case 'end': props.sendInfo({ seconds: data.data.time }); setConfirmed(true); break
        case 'failure': props.sendInfo({ seconds: 0 }); break
        case 'success': props.sendInfo(); break
        default: console.log('No case for iframe manager'); break
      }
    }
    window.addEventListener('message', parseMessageEvents)

    return () => window.removeEventListener('message', parseMessageEvents)
  }, [props.detail.streaming])

  const isTestAlreadyDone = () => {
    if (isVideoWatched) {
      if (!props.detail.passed_date || props.detail.passed_date === '') {
        return true
      }
    }
    return false
  }

  const updatePlayer = string => {
    const duration = refPlayer.current.getDuration()
    let progress = refPlayer.current.getCurrentTime()
    switch (string) {
      case 'forward':
        progress += 30
        break

      default:
        progress -= 30
        break
    }
    if (progress < 0) progress = 0
    if (progress > duration) progress = duration
    refPlayer.current.seekTo(progress, 'seconds')
  }

  const restartPlayer = () => {
    console.log('restart video')
    setRestartModalOpen(false)
    refPlayer.current.seekTo(0, 'seconds')
    props.sendInfo({ seconds: 1, reset: true })
  }

  const openRestartPlayer = () => {
    setRestartModalOpen(true)
  }

  const isAlreadyTested = () => {
    if (props.detail.status === 'TESTED') return true
    return false
  }

  const isPlaying = () => {
    if (props.isMouseStill || (isConfirmed && !props.detail.streaming) || props.detail.status === 'TESTED') {
      return false
    }
    return true
  }

  const onEnd = () => {
    props.sendInfo(refPlayer.current.getDuration())
    setConfirmed(true)
    props.stopTimerMouse()
  }

  useEffect(() => {
    const innerWin = document.getElementsByTagName('iframe')['zoom-player']
    if (!innerWin) {
      console.warn('Cannot connect to iframe')
      return
    }
    console.log('Sending to zoom', isPlaying())
    innerWin.contentWindow.postMessage({ command: 'setPlaying', val: isPlaying() }, '*')
  }, [props.isMouseStill, props.detail.status])

  return (
    <div className='flex w-full flex-col justify-between items-center'>
      {isRestartModalOpen && (
        <RestartModal restart={() => restartPlayer()} closeModal={() => setRestartModalOpen(false)} />
      )}
      {props.url && !props.detail.streaming && !isConfirmed && (
        <ReactPlayer
          playing={isPlaying()}
          ref={refPlayer}
          width='100%'
          style={{ width: '100%', background: 'black', borderRadius: '0.5rem', height: '16rem', padding: '1rem 0 1rem 0' }}
          url={props.url || ''}
          className='rounded-lg bg-black h-64 py-4 player-container'
          data-aos='fade-top'
          progressInterval={20000}
          onReady={() => props.timers.video_time ? refPlayer.current.seekTo(props.timers.video_time, 'seconds') : console.log('starting')}
          onPause={(data) => { console.log('pause'); props.stopTimerMouse(); props.sendInfo(data) }}
          onProgress={(data) => props.updateVideoInfo(data)}
          onSeek={(data) => console.log('Il video riprende a ' + data + ' secondi')}
          onEnded={() => onEnd()}
          controls
        >
          <p>Il tuo browser non supporta la riproduzione del video.</p>
        </ReactPlayer>
      )}
      {props.url && props.detail.streaming && props.detail.streaming_key && (
        <>
          {isIframeLoading && (
            <Loader
              type='Circles'
              color='#2ba0d0'
              height={100}
              width={100}
              timeout={10000} // 3 secs
              className='mx-auto'
            />
          )}
          <iframe
            id='zoom-player'
            title='zoom-player'
            onLoad={() => setIframeLoading(false)}
            src={`${ZOOM_BASE_URL}/?skey=${props.detail.streaming_key}&streaming_code=${props.streamingCode}&userName=${account.name}&code=${encodeURI(props.url)}`}
            width='100%'
            style={{ marginTop: 10 }}
            allow={`microphone ${ZOOM_BASE_URL}; camera ${ZOOM_BASE_URL}`}
            height={isIframeLoading ? '300px' : '600px'}
          />
        </>
      )}
      {!props.detail.streaming && !isConfirmed && (
        <div className='flex w-full flex-col md:flex-row justify-between items-center my-4'>
          <button
            disabled={isConfirmed}
            className={`${isConfirmed ? 'opacity-50' : 'opacity-1'} w-full md:w-auto outline-none bg-white flex shadow-box rounded-lg text-white min-h-12 mb-4 md:mb-0`}
            onClick={() => updatePlayer('')}
          >
            <span className='text-sm lg:text-base text-blue py-2 px-8 font-semibold cursor-pointer flex items-center w-full m-auto'>
              <img src='rewind.svg' alt='rewind icon' className='w-8 mr-4 img-to-blue' />INDIETRO 30s
            </span>
          </button>
          <button
            disabled={isConfirmed}
            className={`${isConfirmed ? 'opacity-50' : 'opacity-1'} w-full md:w-auto outline-none bg-white flex shadow-box rounded-lg text-white min-h-12`}
            onClick={() => openRestartPlayer()}
          >
            <span className='text-sm lg:text-base text-blue py-2 px-8 font-semibold cursor-pointer uppercase w-full m-auto'>RICOMINCIA DALL’INIZIO IL CORSO
            </span>
          </button>
        </div>
      )}
      <p className='text-black text-base my-8'>{props.detail.description}</p>
      <div className='flex w-full justify-start items-center mb-4'>
        {isMessage && isMessage.length && <span className='ml-4 text-red-600 font-semibold text-lg'>{isMessage}</span>}
      </div>
      {isTestAlreadyDone() ? (
        <button
          className={`${(isConfirmed || props.detail.streaming) ? 'opacity-1 pointer-events-auto' : 'opacity-50 pointer-events-none'} outline-none bg-blue flex shadow-box rounded-lg text-white`}
          onClick={() => handleTestClick()}
        >
          <span className='text-white text-base uppercase py-2 px-8 font-semibold cursor-pointer'>
            Vai al test finale
          </span>
        </button>
      ) : null}
    </div>
  )
}

export { Video }
