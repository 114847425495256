import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { fetchList } from './../utils'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import Loader from 'react-loader-spinner'
import AOS from 'aos'
import 'aos/dist/aos.css'

const InfoCourse = props => {
  const formatDate = (date) => {
    let newDate = ' '
    if (date && date.length) {
      date = date.split('-')
      newDate = newDate + date[2] + '/' + date[1] + '/' + date[0]
    }
    return newDate + ' '
  }
  const formatTime = (time) => {
    let newTime = ' '
    if (time && time.length) {
      newTime = newTime + time
    }
    return ' ' + newTime
  }

  if (props.item.streaming) {
    return (
      <div className='flex flex-col md:flex-row justify-start items-baseline mt-1'>
        <span className='text-white font-bold uppercase mr-2 text-md bg-green rounded px-2 my-1 md:my-0'> Streaming</span>
        {!!props.item.streaming_start_date && !!props.item.streaming_start_time && (
          <div className='flex flex-col md:flex-row justify-start items-baseline'>
            <span className='font-semibold text-xs md:text-base my-1 md:my-0'>
              <b className='font-normal'>DATA:</b>{formatDate(props.item.streaming_start_date)}
            </span>
            <span className='font-semibold text-xs md:text-base my-1 md:my-0'>
              <b className='font-normal md:ml-3'>ORA INIZIO:</b>{formatTime(props.item.streaming_start_time)}
            </span>
            <span className='font-semibold text-xs md:text-base my-1 md:my-0'>
              <b className='font-normal md:ml-3'>ORA FINE:</b>{formatTime(props.item.streaming_end_time)}
            </span>
          </div>
        )}
      </div>
    )
  } else if ((props.item.days_missing !== '-') || (props.item.status === 'PASSED')) {
    return (
      <div className='flex justify-between items-baseline'>
        {props.item.days_missing !== '-' && (
          <div className='flex justify-start items-baseline'>
            <span className='text-black uppercase mr-2 text-xs md:text-base'> SCADENZA:</span>
            <span className={`${parseInt(props.item.days_missing) > 5 ? 'text-black' : 'text-red-600'}  font-semibold text-xs md:text-base`}> {parseInt(props.item.days_missing) > 0 ? props.item.days_missing : 0} giorni rimanenti</span>
          </div>
        )}
        {props.item.status === 'PASSED' && (
          <span className='text-black font-bold uppercase mr-2 flex-grow text-right text-base md:text-lg'>COMPLETATO</span>
        )}
      </div>
    )
  } else {
    return ''
  }
}

const List = () => {
  const [list, setList] = useState([])
  const [message, setMessage] = useState('')
  const [isLoading, setLoading] = useState(false)

  const formatString = str => {
    if (str && str.length && str.length > 180) {
      str = str.substring(0, 180)
      str += '...'
    }
    return str
  }

  const getList = () => {
    setLoading(true)
    fetchList()
      .then(res => {
        if (res && res.length) setList(res)
      })
      .catch(err => console.log(err.message))
      .finally(() => setLoading(false))
  }

  const initAOS = () => {
    AOS.init({
      duration: 1000
    })
  }

  const checkExpired = () => {
    if (window.location.href.includes('expired')) {
      setMessage("Il corso é scaduto. Richiedine la ri-attivazione al tuo referente o all'assistenza di GrowUp.")
      setTimeout(() => setMessage(''), 10000)
    }
  }

  const compare = (l1, l2) => {
    const a1 = l1.status === 'PASSED' ? 1 : 0
    const a2 = l2.status === 'PASSED' ? 1 : 0
    return a1 - a2
  }

  useEffect(getList, [])
  useEffect(initAOS, [])
  useEffect(checkExpired, [])

  return (
    <article className=''>
      <h1 className='text-4xl font-semibold text-black mb-12 leading-snug'>Elenco dei corsi disponibili</h1>
      {isLoading ? (
        <div className='flex justify-center w-full'>
          <Loader
            type='Circles'
            color='#2ba0d0'
            height={100}
            width={100}
            timeout={10000}
            className='mx-auto'
          />
        </div>
      ) : (
        <div className='flex flex-col'>
          {list && list.length
            ? list.sort((l1, l2) => compare(l1, l2)).map(l => (
              <div key={l.id} className={`${l.status === 'PASSED' ? 'opacity-50 pointer-events-none' : ''} bg-white rounded-lg shadow-box lg:mb-4 mb-8`}>
                <Link to={`/courses/${l.id}`} className={`${l.status === 'PASSED' ? 'pointer-events-none opacity-50 ' : ''} flex flex-col lg:flex-row justify-between py-6 px-6 md:px-8`}>
                  <div className='flex flex-col w-full lg:w-3/4 mb-2 lg:mb-0'>
                    <h3 className='text-black font-semibold text-lg'> {l.name}</h3>
                    <p className='text-black text-sm leading-5 my-2'> {formatString(l.description)}
                    </p>
                    <div className='flex justify-start items-baseline'>
                      <span className='text-black uppercase mr-2 text-xs md:text-base'> DURATA:</span>
                      <span className='text-black font-semibold text-xs md:text-base'> {l.duration}</span>
                    </div>
                    <InfoCourse item={l} />
                  </div>
                  <div className='max-w-full flex justify-center items-center w-1/4 mx-auto md:w-auto'>
                    <img src='play-button.png' className='lg:w-11/12' alt='play icon' />
                  </div>
                </Link>
              </div>
            ))
            : (
              <span className='text-lg text-black'>Nessun corso trovato</span>
            )}
          {message && message.length && (
            <div className='fixed bottom-0 md:mb-10 flex tex-center w-full left-0 lg:ml-10 md:w-auto shadow-total z-50 bg-white rounded-lg'>
              <img onClick={() => setMessage('')} className='m-2 md:m-4 absolute top-0 right-0 cursor-pointer w-3' src='close.svg' alt='close icon' />
              <span className='mb-4 text-md text-red-600 font-semibold px-8 pb-2 pt-6'>{message}</span>
            </div>
          )}
        </div>
      )}
    </article>
  )
}

export { List }
