import React from 'react'
import { Route, HashRouter as Router } from 'react-router-dom'
import { Login } from './components/Login'
import { Main } from './components/Main'
import { GlobalStoreProvider } from './stores/Global'
import './App.css'

// import { auth } from './utils'

const Body = ({ history, location }) => {
  return (
    <Router>
      {window.localStorage.getItem('JWT') && window.localStorage.getItem('loggedAccount') ? (
        <Main />
      ) : (
        <Login />
      )}
    </Router>
  )
}

const App = () => {
  return <Route exact path='/' component={Body} />
}

export default () => (

  <GlobalStoreProvider>
    <App />
  </GlobalStoreProvider>
)
