import React, { useContext, useState, useEffect } from 'react'
import { GlobalStore } from '../stores/Global'

const Msg = props => {
  const [isVisible, setVisible] = useState(true)
  const globalStore = useContext(GlobalStore)

  useEffect(() => {
    setTimeout(() => {
      setVisible(false)
      return globalStore.dispatch({
        type: 'RM_NOTIFICATION'
      })
    }, (props.duration || 3000))
  }, [globalStore, props.duration])
  return (
    <div className={`absolute bottom-0 left-0 z-100 bg-white px-4 py-4 shadow-notification rounded-tr-lg rounded-br-lg ${isVisible ? '' : 'hidden'}`}>
      <span>{props.message}</span>
    </div>
  )
}

export { Msg }
