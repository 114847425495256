import React, { useContext } from 'react'
import { logout, renderAccountData } from './../utils'
import { GlobalStore } from '../stores/Global'

const Sidebar = ({ history }) => {
  const globalStore = useContext(GlobalStore)

  let arrayToRender = []
  try {
    arrayToRender = renderAccountData(globalStore.state.loggedAccount || JSON.parse(window.localStorage.getItem('loggedAccount')))
  } catch (e) {
    console.error(e)
  }
  return (
    <div id='sidebar' className='hidden lg:flex w-1/4 fixed flex-col bg-white h-full rounded-right min-h-screen px-8 xl:px-12 py-20 justify-between items-start z-30'>
      <div className='w-full xl:w-1/2 max-w-full mb-12'>
        <img src='logo.jpg' alt='logo' className='w-full' />
      </div>
      <div className='flex flex-col pb-16 flex-grow'>
        {arrayToRender.map((item, index) => (
          <div key={index} className='flex flex-col pb-4'>
            <span className='text-base font-semibold text-light-grey'>{item.key}</span>
            <span className='text-lg text-black'> {item.value}</span>
          </div>
        ))}

      </div>
      <div onClick={() => logout(history)} className='bg-blue flex shadow-box rounded-lg text-white mx-auto cursor-pointer'>
        <span className='text-white text-sm uppercase py-2 px-4 xl:px-8 font-semibold'>
          Logout
        </span>
      </div>
    </div>
  )
}

export { Sidebar }
