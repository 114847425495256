import React, { useContext, useEffect, useState } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Detail } from './Detail'
import { List } from './List'
import { Sidebar } from './Sidebar'
import { Header } from './Header'
import { Msg } from './Msg'
import { GlobalStore } from '../stores/Global'
import { PrivacyModal } from './PrivacyModal'

const Main = ({ history }) => {
  const globalStore = useContext(GlobalStore)
  const [isPrivacyNotAccepted, setPrivacyNotAccepted] = useState(false)
  const [event, setEvent] = useState({})

  useEffect(() => {
    let account = {}
    try {
      account = JSON.parse(window.localStorage.getItem('loggedAccount'))
    } catch (e) {
      console.error(e)
    }
    if (account) {
      console.log(account.privacy_accepted)
    }
    if (account && Object.keys(account).length && (!account.privacy_accepted || account.privacy_accepted === null)) {
      setPrivacyNotAccepted(true)
    }
  }, [])

  return (
    isPrivacyNotAccepted
      ? (<PrivacyModal />)
      : (
        <main className='w-full flex bg-light-blue min-h-screen'>

          {globalStore.state.latestErr &&
            <Msg type='error' message={globalStore.state.latestMsg} />}

          <Header />

          <Sidebar />

          <div id='list' onMouseMove={e => { e.preventDefault(); setEvent(e) }} className='w-full pt-20 pb-8 lg:py-16 lg:w-3/4 ml-auto flex flex-col overflow-y-auto px-8'>
            <Switch>
              {window.location.href && !window.location.href.split('/#/')[1].includes('login') && (
                <Route path='/courses/:id' render={(routerProps) => <Detail {...routerProps} mouse={event} />} />
              )}}
              <Route exact path='/login-token'>
                <Redirect to='/' />
              </Route>
              <Route exact path='/login'>
                <Redirect to='/' />
              </Route>
              <Route path='/' component={List} />
            </Switch>
          </div>
        </main>
      )
  )
}

export { Main }
