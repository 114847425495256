import React from 'react'
const MouseModal = props => {
  return (
    <div id='mousemodal' className='fixed flex top-0 left-0 h-screen w-screen z-50'>
      <div className='fixed top-0 left-0 h-screen w-screen z-40 bg-black opacity-75' />
      <div className='bg-white w-10/12 md:w-3/5 lg:w-1/3 m-auto p-8 rounded-lg z-50 flex flex-col text-center'>
        <h2 className='text-black font-semibold text-lg mb-4'>Stai ancora guardando?</h2>
        <div className='flex justify-evenly flex-col md:flex-row mt-4'>
          <div onClick={() => props.closeModal()} className='bg-blue flex shadow-box rounded-lg text-white mx-auto cursor-pointer w-40 justify-center'>
            <span className='text-white text-sm uppercase py-2 px-4 xl:px-8 font-semibold'>Continua</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export { MouseModal }
