import { API_URL } from './config'

const auth = () => {
  // auth with JWT
  return new Promise((resolve, reject) => resolve(false))
}

const fetchList = async (filters = {}) => {
  return fetch(`${API_URL}/courses/get-courses?token=digitlife&statuses=ASSIGNED,STARTED,TESTED,PASSED&jwt=${window.localStorage.getItem('JWT')}`)
    .then(res => {
      if (res.status === 401) logoutJWT()
      return res.json()
    })
    .catch(err => {
      console.error(err)
    })
}

const fetchDetail = async id => {
  return fetch(`${API_URL}/courses/get-detail-course?token=digitlife&jwt=${window.localStorage.getItem('JWT')}&id=${id}`)
    .then(res => {
      if (res.status === 401) logoutJWT()
      return res.json()
    })
    .catch(err => console.error(err))
}

const logout = (history) => {
  window.localStorage.clear()
  window.location.href = '/'
}

const logoutJWT = () => {
  window.localStorage.removeItem('JWT')
  window.localStorage.removeItem('loggedAccount')
  window.location.href = '/#/login?invalid'
}

const handleStartTest = data => {
  const jwt = window.localStorage.getItem('JWT')
  return fetch(`${API_URL}/tests/start-test?token=digitlife`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ id: data.id, jwt: jwt })
  })
    .then(res => {
      if (res.status === 401) logoutJWT()
      if (res.ok) {
        return res
      } else {
        throw new Error()
      }
    })
    .catch(err => err)
}

const handleStartCourse = data => {
  const jwt = window.localStorage.getItem('JWT')
  return fetch(`${API_URL}/courses/start-course?token=digitlife`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ id: data.id, jwt: jwt })
  })
    .then(res => res.json())
    .catch(err => err)
}

const checkQuestions = test => {
  for (let i = 0; i < test.length; i++) {
    if (!test[i].answer || test[i].answer === '') {
      return false
    }
  }
  return true
}

const formatAnswer = (data, detail) => {
  const answerObj = {
    course_id: parseInt(detail.id),
    test_id: detail.test_id
  }
  const answers = []
  for (let i = 0; i < data.length; i++) {
    if (data[i].answer && data[i].answer !== '') {
      answers.push({
        question_id: data[i].id,
        answer_id: data[i].answer
      })
    }
  }
  answerObj.answers = answers
  return answerObj
}

const submitTest = (data, test) => {
  const answer = formatAnswer(data, test)
  return submitFormattedTest(answer)
}

const submitFormattedTest = (answer) => {
  const jwt = window.localStorage.getItem('JWT')
  answer.jwt = jwt
  const path = window.location.href.split('courses/')[1]
  const stringAnswer = JSON.stringify(answer)
  window.localStorage.setItem(path, stringAnswer)
  return fetch(`${API_URL}/tests/submit-test?token=digitlife`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(answer)
  })
    .then(res => {
      if (res.status === 401) logoutJWT()
      else if (res.status === 403) window.location.href = '/#/list?expired'
      else {
        window.localStorage.removeItem(path)
        return res.json()
      }
    })
    .catch(err => err)
}

const renderAccountData = object => {
  const keysToRender = ['name', 'surname', 'company_name', 'fiscal_code']
  const keysToIta = ['Nome', 'Cognome', 'Azienda', 'Codice fiscale']
  const arrayToRender = []
  const keys = Object.keys(object)
  for (let i = 0; i < keys.length; i++) {
    if (keysToRender.includes(keys[i])) {
      const arrayIndex = keysToRender.indexOf(keys[i])
      arrayToRender.push({
        key: keysToIta[arrayIndex],
        value: object[keys[i]]
      })
    }
  }
  return arrayToRender
}

// TIMERS
const fetchTime = async id => {
  return fetch(`${API_URL}/courses/get-time?token=digitlife&jwt=${window.localStorage.getItem('JWT')}&id=${id}`)
    .then(res => {
      if (res.status === 401) logoutJWT()
      if (!res.ok) {
        res.text().then(text => {
          if (text === 'Course not found') {
            window.location.href = '/#/list'
          }
        })
      }
      return res.json()
    })
    .catch(err => console.error(err))
}

const sendTime = (id, course, video, reset = false) => {
  if (course > 0) {
    const video_time = video
    const course_time = course
    const jwt = window.localStorage.getItem('JWT')
    const data = {
      id: id,
      video_time: video_time,
      course_time: course_time,
      jwt: jwt,
      reset: reset
    }
    return fetch(`${API_URL}/courses/set-time?token=digitlife`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(res => {
        if (res.status === 401) logoutJWT()
        if (res.status === 403) {
          window.location.href = '/#/list?expired'
        }
        return res.json()
      })
      .catch(err => err)
  }
}

export { auth, fetchList, fetchDetail, handleStartTest, handleStartCourse, submitTest, submitFormattedTest, checkQuestions, renderAccountData, logout, fetchTime, sendTime }
