const API_URL =
    process.env.API_URL ||
    process.env.REACT_APP_API_URL ||
    process.env.GATSBY_API_URL ||
    'https://stagingapp-elearning.growup.srl/api'

const ZOOM_BASE_URL = process.env.REACT_APP_ZOOM_BASE_URL || 'https://growup-zoom-develop.netlify.app'

const INACTIVITY_TIMER = 600 // 10 minuti
const POLLING_TIMER = 60 // 1 minuto
export { API_URL, POLLING_TIMER, INACTIVITY_TIMER, ZOOM_BASE_URL }
