import React from 'react'

export const GlobalStore = React.createContext()

const DEFAULT_LOCALE = process.env.REACT_APP_DEFAULT_LOCALE || 'en'

const initialState = {
  started: false,
  isSidebarVisible: true,
  loggedAccount: null,
  isAdmin: false,
  jwt: null,
  locale: DEFAULT_LOCALE,
  users: [],
  audiences: [],
  surveys: [],
  latestMsg: null,
  latestError: null
}

function reducer (state, action) {
  switch (action.type) {
    case 'FINISH_BOOTSTRAP':
      return { ...state, started: true }
    case 'LOGIN':
      return {
        ...state,
        loggedAccount: action.payload.account,
        // isAdmin: action.payload.account.permissions === 100,
        jwt: action.payload.jwt
        // locale:
        //   action.payload.account.locale ||
        //   state.locale ||
        //   window.navigator.language ||
        //   DEFAULT_LOCALE
      }
    case 'REPORT':
      return {
        ...state,
        reportMail: action.payload.tenant
      }
    case 'ERROR':
      if (action.payload) console.error(action.payload)
      return {
        ...state,
        latestError: action.payload
      }
    case 'MSG':
      return {
        ...state,
        latestMsg: action.payload
      }
    case 'LOGOUT':
      return {
        ...state,
        loggedAccount: null,
        isAdmin: false,
        jwt: null,
        users: [],
        latestError: null
      }
    case 'NOTIFICATION':
      return {
        ...state,
        latestError: true,
        latestMsg: action.payload
      }
    case 'RM_NOTIFICATION':
      return {
        ...state,
        latestError: false
      }
    case 'UPDATE_ACCOUNT':
      return {
        ...state,
        loggedAccount: action.payload
      }
    case 'UPDATE_REPORT':
      return {
        ...state,
        reportMail: action.payload
      }
    case 'UPDATE_AUDIENCE':
      return {
        ...state,
        audience: action.payload
      }
    case 'GET_OVERVIEW':
      return {
        ...state,
        overview: action.payload
      }
    case 'GET_RADIAL':
      return {
        ...state,
        radial: action.payload
      }
    case 'GET_STACKED':
      return {
        ...state,
        stacked: action.payload
      }
    case 'GET_TICKETS':
      return {
        ...state,
        tickets: action.payload
      }
    case 'GET_AUDIENCE':
      return {
        ...state,
        audience: action.payload
      }
    case 'GET_SURVEYS':
      return {
        ...state,
        surveys: action.payload
      }
    case 'GET_LOGS':
      return {
        ...state,
        logs: action.payload
      }
    case 'CHANGE_LOCALE':
      return { ...state, locale: action.payload }
    case 'FETCH_USERS':
      return { ...state, users: action.payload }
    case 'TOGGLE_SIDEBAR':
      return { ...state, isSidebarVisible: !state.isSidebarVisible }
    default:
      return state
  }
}

export function GlobalStoreProvider (props) {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const value = { state, dispatch }
  return (
    <GlobalStore.Provider value={value}>{props.children}</GlobalStore.Provider>
  )
}
