import React, { useState, useContext, useEffect } from 'react'
import { GlobalStore } from '../stores/Global'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import Loader from 'react-loader-spinner'
import { API_URL } from '../config'
import AOS from 'aos'
import 'aos/dist/aos.css'

const Login = props => {
  const getForm = () => {
    if (window.location.href.split('/#/')[1].includes('password-recovery')) {
      return <RecoveryForm {...props} />
    } else {
      if (window.location.href.split('/#/')[1].includes('login-token')) {
        return <LoginFormWithToken />
      } else {
        return <LoginForm />
      }
    }
  }

  useEffect(() => {
    if (window.localStorage.getItem('JWT')) {
      window.localStorage.removeItem('JWT')
    }
  })
  useEffect(() => {
    AOS.init({
      duration: 1000
    })
  })

  return (
    <div data-aos='fade-top' className='z-0 flex items-center justify-center w-screen h-screen overflow-y-hidden bg-black'>
      <div className='fixed z-10 w-screen h-screen overflow-y-hidden opacity-50 login-img' />
      <div data-aos='fade' className='z-20 flex flex-col items-start justify-start w-screen h-screen p-8 m-auto bg-white lg:w-1/3 lg:h-auto lg:rounded-lg'>
        <div className='w-1/2 max-w-full mb-8'>
          <img src='logo.jpg' alt='logo' className='w-3/5' />
        </div>
        <h2 className='text-blue font-bold text-xl uppercase mb-4'>Benvenuto sulla piattaforma</h2>
        {getForm()}
      </div>
    </div>
  )
}

const LoginForm = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [isMessage, setMessage] = useState('')

  const globalStore = useContext(GlobalStore)

  const onChangeEmail = e => {
    const { target } = e
    const { value } = target
    setEmail(value)
  }

  const onChangePassword = e => {
    const { target } = e
    const { value } = target
    setPassword(value)
  }

  const handleSubmit = e => {
    e.preventDefault()
    setLoading(true)
    const xhr = new XMLHttpRequest()
    xhr.onreadystatechange = function () {
      if (this.readyState === 4) {
        if (this.status === 200) {
          // THEN
          setLoading(false)
          const response = JSON.parse(this.responseText)
          window.localStorage.setItem('JWT', response.jwt)
          window.localStorage.setItem('loggedAccount', JSON.stringify(response.account))
          window.location.href = '/#/'
          window.location.reload()
          return globalStore.dispatch({
            type: 'LOGIN',
            payload: response
          })
        } else {
          console.log(this)
          setLoading(false)
          setTimeout(() => setMessage('Credenziali errate'), 500)
          console.error(this)
        }
      }
    }
    xhr.open('POST', `${API_URL}/customers/login?token=digitlife`)
    xhr.setRequestHeader('Accept', 'application/json')
    xhr.send(JSON.stringify({ email: email, password: password }))
  }

  useEffect(() => {
    if (window.location.href.includes('invalid')) {
      setMessage("Sessione scaduta. Per favore ri-effettua l'accesso alla piattaforma con le tue credenziali")
      setTimeout(() => setMessage(''), 10000)
    }
  }, [])

  return (
    <form
      className='flex flex-col justify-center w-full h-full py-4'
      onSubmit={handleSubmit}
    >
      <div className='flex flex-col mb-4'>
        <span className='text-black text-lg mb-4'>Accedi alla piattaforma inserendo la tua email e la password da te impostata</span>
        <label className='mb-2 text-lg text-black'>Email</label>
        <input
          type='email'
          id='email'
          value={email}
          onChange={onChangeEmail}
          className='flex items-center h-8 p-1 text-sm text-black border border-black rounded-lg outline-none'
        />
      </div>
      <div className='flex flex-col mb-8'>
        <label className='mb-2 text-lg text-black'>Password</label>
        <input
          type='password'
          id='password'
          value={password}
          onChange={onChangePassword}
          className='flex items-center h-8 p-1 text-sm text-black border border-black rounded-lg outline-none'
        />
      </div>
      <a style={{ color: '#2ba0d0', cursor: 'pointer' }} href='/#/login-token'>Hai un codice? Clicca qui per loggarti</a>
      <button
        className='flex px-8 py-2 mx-auto mt-4 text-base font-semibold text-white uppercase rounded-lg outline-none bg-blue shadow-box'
        type='submit'
      >
        {isLoading ? (
          <Loader
            type='ThreeDots'
            color='#FFF'
            height={25}
            width={50}
            timeout={10000} // 3 secs
            className='mx-auto'
          />
        ) : 'LOG IN'}
      </button>
      <div className='flex w-full justify-start items-center mb-4'>
        {isMessage && isMessage.length && <span className='mt-4 mx-auto text-red-600 font-semibold text-lg'>{isMessage}</span>}
      </div>
    </form>
  )
}

const LoginFormWithToken = () => {
  const [token, setToken] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [isMessage, setMessage] = useState('')

  const globalStore = useContext(GlobalStore)

  const onChangeToken = e => {
    const { target } = e
    const { value } = target
    setToken(value)
  }

  const handleSubmit = e => {
    e.preventDefault()
    setLoading(true)
    const xhr = new XMLHttpRequest()
    xhr.onreadystatechange = function () {
      if (this.readyState === 4) {
        if (this.status === 200) {
          // THEN
          setLoading(false)
          const response = JSON.parse(this.responseText)
          window.localStorage.setItem('JWT', response.jwt)
          window.localStorage.setItem('loggedAccount', JSON.stringify(response.account))
          window.location.href = '/#/'
          window.location.reload()
          return globalStore.dispatch({
            type: 'LOGIN',
            payload: response
          })
        } else {
          console.log(this)
          setLoading(false)
          setTimeout(() => setMessage('Credenziali errate'), 500)
          console.error(this)
        }
      }
    }
    xhr.open('POST', `${API_URL}/customers/login-token?token=digitlife`)
    xhr.setRequestHeader('Accept', 'application/json')
    xhr.send(JSON.stringify({ token }))
  }

  return (
    <form
      className='flex flex-col justify-center w-full h-full py-4'
      onSubmit={handleSubmit}
    >
      <div className='flex flex-col mb-4'>
        <span className='text-black text-lg mb-4'>Inserisci il token di accesso</span>
        <label className='mb-2 text-lg text-black'>Token</label>
        <input
          type='text'
          id='token'
          value={token}
          onChange={onChangeToken}
          className='flex items-center h-8 p-1 text-sm text-black border border-black rounded-lg outline-none'
        />
      </div>
      <button
        className='flex px-8 py-2 mx-auto mt-4 text-base font-semibold text-white uppercase rounded-lg outline-none bg-blue shadow-box'
        type='submit'
      >
        {isLoading ? (
          <Loader
            type='ThreeDots'
            color='#FFF'
            height={25}
            width={50}
            timeout={10000} // 3 secs
            className='mx-auto'
          />
        ) : 'LOG IN'}
      </button>
      <div className='flex w-full justify-start items-center mb-4'>
        {isMessage && isMessage.length && <span className='mt-4 mx-auto text-red-600 font-semibold text-lg'>{isMessage}</span>}
      </div>
    </form>
  )
}

const RecoveryForm = props => {
  const [confirmPassword, setConfirmPassword] = useState('')
  const [password, setPassword] = useState('')
  const [messageError, setMessageError] = useState('')
  const [isMessage, setMessage] = useState('')

  const handleChange = event => {
    switch (event.target.id) {
      case 'confirmPassword':
        setConfirmPassword(event.target.value)
        break
      case 'password':
        setPassword(event.target.value)
        break
      default:
        break
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    setMessage('')
    setMessageError('')
    const token = window.location.href.split('token=')[1]
    if (password === confirmPassword) {
      const xhr = new XMLHttpRequest()
      xhr.onreadystatechange = function () {
        if (this.readyState === 4 && this.status === 200) {
          setMessage('Password impostata. Verrai re-indirizzato al login.')
          setTimeout(() => { window.location.href = '/#/login' }, 5000)
        } else if (this.status === 401 || this.status === 400) {
          setMessageError("Link reimpostazione password non valido o scaduto. Richiedine un altro al tuo referente o all'assistenza di GrowUp.")
        }
      }
      xhr.open('POST', `${API_URL}/customers/reset-password?token=digitlife`)
      xhr.setRequestHeader('Accept', 'application/json')
      xhr.send(JSON.stringify({ password: password, jwt: token }))
    } else {
      setMessageError('Le password non combaciano.')
    }
  }

  useEffect(() => {
    const token = window.location.href.split('token=')[1]
    const xhr = new XMLHttpRequest()
    xhr.open('POST', `${API_URL}/customers/set-read-email?token=digitlife`)
    xhr.setRequestHeader('Accept', 'application/json')
    xhr.send(JSON.stringify({ jwt: token }))
  }, [])

  return (
    <form className='flex flex-col w-full' onSubmit={handleSubmit}>
      <div className='flex flex-col mb-4'>
        <span className='text-black text-lg mb-4'>Imposta una password e confermala inserendola una seconda volta </span>
        <label className='mb-2 text-lg text-black'>Password</label>
        <input
          type='password'
          id='password'
          value={password}
          onChange={ev => handleChange(ev)}
          className='flex items-center h-8 p-1 text-sm text-black border border-black rounded-lg'
        />
      </div>
      <div className='flex flex-col mb-4'>
        <label className='mb-2 text-lg text-black'>Confirm Password</label>
        <input
          type='password'
          id='confirmPassword'
          value={confirmPassword}
          onChange={ev => handleChange(ev)}
          className='flex items-center h-8 p-1 text-sm text-black border border-black rounded-lg outline-none'
        />
      </div>
      {isMessage.length ? (
        <span className='mb-4 text-sm font-bold text-green-600'>{isMessage}</span>
      ) : messageError.length ? (
        <span className='mb-4 text-sm text-red-600'>{messageError}</span>
      ) : null}

      <button
        className='flex px-8 py-2 mx-auto mt-4 text-base font-semibold text-white uppercase rounded-lg outline-none bg-blue shadow-box md:mt-0'
        type='submit'
      >
        INVIA
      </button>
    </form>
  )
}
export { Login }
