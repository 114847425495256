import React, { useState, useContext } from 'react'
import { logout, renderAccountData } from './../utils'
import { GlobalStore } from '../stores/Global'

const Header = ({ history }) => {
  const globalStore = useContext(GlobalStore)
  const [isSidebarOpen, setSidebarOpen] = useState(false)

  let arrayToRender = []
  try {
    arrayToRender = renderAccountData(globalStore.state.loggedAccount || JSON.parse(window.localStorage.getItem('loggedAccount')))
  } catch (e) {
    console.error(e)
  }

  return (
    <div id='header' className={`flex lg:hidden w-full fixed flex-col bg-white transition-all duration-1000 bg-white overflow-hidden items-start z-30 shadow-box ${!isSidebarOpen ? 'rounded-bottom h-16' : 'h-screen'}`}>
      <div style={{ minHeight: '6rem' }} className='h-20 pt-3 flex flex-col mb-8'>
        <div className='fixed h-10 left-50-center'>
          <img src='logo.jpg' alt='logo' className='h-full' />
        </div>
        <div onClick={() => setSidebarOpen(!isSidebarOpen)} className='fixed h-8 mt-1 sm:h-10 sm:mt-0 mr-auto ml-12 opacity-75'>
          <img src='menu.svg' alt='menu' className='h-full cursor-pointer' />
        </div>
      </div>
      <div className='flex flex-col w-full px-8 flex-grow justify-center'>
        <div className='flex flex-col pb-16 flex-grow items-start'>
          {arrayToRender.map((item, index) => (
            <div key={index} style={{ minHeight: '4rem' }} className='flex flex-col pb-4'>
              <span className='text-base font-semibold text-light-grey'>{item.key}</span>
              <span className='text-lg text-black'>{item.value}</span>
            </div>
          ))}

        </div>
        <div onClick={() => logout(history)} className='bg-blue w-3/5 mb-16 justify-center flex shadow-box rounded-lg text-white mx-auto cursor-pointer'>
          <span className='text-white text-md uppercase py-2 px-4 xl:px-8 font-semibold'>
            Logout
          </span>
        </div>
      </div>
    </div>
  )
}

export { Header }
