import React from 'react'
const RestartModal = props => {
  return (
    <div id='restartmodal' className='fixed flex top-0 left-0 h-screen w-screen z-50'>
      <div className='fixed top-0 left-0 h-screen w-screen z-40 bg-black opacity-75' />
      <div className='bg-white w-10/12 md:w-3/5 lg:w-1/3 m-auto p-8 rounded-lg z-50 flex flex-col text-center'>
        <h2 className='text-black font-semibold text-lg mb-4'>Vuoi davvero ricominciare dall'inizio il corso?</h2>
        <div className='flex justify-between flex-col md:flex-row mt-4'>
          <div onClick={() => props.closeModal()} className='bg-blue flex shadow-box rounded-lg text-white mx-auto cursor-pointer w-40 justify-center mb-4 md:mb-0'>
            <span className='text-white text-sm uppercase py-2 px-4 xl:px-8 font-semibold'>Annulla</span>
          </div>
          <div onClick={() => props.restart()} className='bg-white border border-blue flex shadow-box rounded-lg text-white mx-auto cursor-pointer w-40 justify-center'>
            <span className='text-blue text-sm uppercase py-2 px-4 xl:px-8 font-semibold'>Ricomincia</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export { RestartModal }
