import React, { useState } from 'react'
import { logout } from '../utils'
import { API_URL } from '../config'

const PrivacyModal = () => {
  const [isClicked, setClicked] = useState(false)
  const [message, setMessage] = useState('')

  const acceptPrivacy = () => {
    const jwt = window.localStorage.getItem('JWT')
    return fetch(`${API_URL}/customers/accept-privacy?token=digitlife`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ jwt })
    })
      .then(res => {
        let newAccount = window.localStorage.getItem('loggedAccount')
        newAccount = JSON.parse(newAccount)
        newAccount.privacy_accepted = true
        window.localStorage.setItem('loggedAccount', JSON.stringify(newAccount))
        window.location.href = '/'
      })
      .catch(err => {
        console.error(err)
        setMessage('Si é verificato un errore')
      })
  }

  return (
    <div className='fixed h-screen w-screen z-40'>
      <div className='flex h-screen w-screen z-40 bg-black'>
        <div className='fixed z-10 w-screen h-screen overflow-y-hidden opacity-50 login-img' />
        <div className='bg-white w-10/12 md:w-3/5 lg:1/2 m-auto z-50 p-8 rounded-lg'>
          <h2 className='text-black font-semibold text-lg mb-4'> Privacy Policy</h2>
          <p className='text-black text-md mb-4'>Clicca <a className='text-black underline font-bold' rel='noopener noreferrer' href='https://formazione.growup.srl/informativa_utenti.pdf' onClick={() => setClicked(true)} target='_blank'>qui</a> per leggere l'informativa sulla privacy policy. <i>(obbligatorio)</i></p>
          <p className='text-black text-md mb-4'>Una volta letta, clicca su “Prosegui” per accettare i termini e le condizioni della privacy policy e accedere alla piattaforma. </p>

          {message && message.length && (<span className='text-red-600 font-semibold text-sm pb-4'> {message}</span>)}
          <div className='flex justify-evenly flex-col md:flex-row mt-4'>
            <div onClick={() => logout()} className='bg-blue flex shadow-box rounded-lg text-white mx-auto cursor-pointer w-40 justify-center mb-4 md:mb-0'>
              <span className='text-white text-sm uppercase py-2 px-4 xl:px-8 font-semibold'>Logout</span>
            </div>
            <div onClick={() => acceptPrivacy()} className={`bg-blue flex shadow-box rounded-lg text-white mx-auto cursor-pointer w-40 justify-center mt-4 md:mt-0 ${!isClicked ? 'opacity-50 pointer-events-none' : ''}`}>
              <span className='text-white text-sm uppercase py-2 px-4 xl:px-8 font-semibold'>Prosegui </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { PrivacyModal }
